var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      ref: "vspopup-per",
      attrs: {
        title: _vm.$t("permissoes"),
        active: _vm.visible,
        id: "popup-permission",
      },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closePopup,
      },
    },
    [
      _c("Grid", {
        ref: "grid-permission-popup",
        attrs: {
          multipleSelect: true,
          service: _vm.permissionService,
          route_name: "permissions",
          hide_actions: "true",
          order_column: "name",
          "request-params": _vm.filtersGrid,
        },
        on: { changedSelection: _vm.changedSelection },
      }),
      _c(
        "div",
        { staticClass: "flex flex-row justify-end gap-4" },
        [
          _c("vs-button", { on: { click: _vm.addPermissions } }, [
            _vm._v(" " + _vm._s(_vm.$t("adicionar")) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }