var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            { attrs: { color: _vm.colorx } },
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("functionality.title") } },
                [
                  _c("div", { staticClass: "flex flex-col gap-4" }, [
                    _c("div", { staticClass: "flex flex-col gap-2" }, [
                      _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:191",
                                expression: "'required|max:191'",
                              },
                            ],
                            staticClass: "w-full required",
                            attrs: {
                              id: "functionality_name",
                              label: _vm.$t("functionality.name"),
                            },
                            model: {
                              value: _vm.model.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("name"),
                                  expression: "errors.has('name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(_vm._s(_vm.$t("functionality.module"))),
                          ]),
                          _c("select-suggestion", {
                            staticClass: "required",
                            attrs: {
                              column: "id,name",
                              model: "Module",
                              appendClearOption: true,
                              placeholderText: "Digite o nome ou id do módulo",
                            },
                            model: {
                              value: _vm.module,
                              callback: function ($$v) {
                                _vm.module = $$v
                              },
                              expression: "module",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _c("vs-textarea", {
                            attrs: {
                              id: "functionality_description",
                              label: _vm.$t("functionality.description"),
                            },
                            model: {
                              value: _vm.model.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "description", $$v)
                              },
                              expression: "model.description",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("description"),
                                  expression: "errors.has('description')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("description")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "w-full flex gap-2 justify-end" },
                      [
                        !_vm.isEdit()
                          ? _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "functionalities.create",
                                    expression: "'functionalities.create'",
                                  },
                                ],
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.createOrUpdate },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("action.save")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isEdit()
                          ? _c(
                              "vs-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "functionalities.edit",
                                    expression: "'functionalities.edit'",
                                  },
                                ],
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.createOrUpdate },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("action.save")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "vs-button",
                          {
                            attrs: { type: "border" },
                            on: { click: _vm.cancel },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm.isEdit()
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("actions") } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vx-row mb-6",
                          staticStyle: { "padding-top": "30px" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { icon: "add" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showPopupPermission = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("adicionar")) + " "
                                  ),
                                ]
                              ),
                              _c("vs-divider"),
                              _c("Grid", {
                                key: _vm.gridKey,
                                ref: "grid-permission",
                                attrs: {
                                  service: _vm.service,
                                  route_name: "functionalities",
                                  order_column: "name",
                                  route_grid_path: _vm.permissionsFuncPath,
                                  route_delete_path: _vm.permissionsFuncPath,
                                  hide_edit: "true",
                                  delegate: {
                                    destroy: () =>
                                      _vm.destroyPermissionFunctionality,
                                  },
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "w-full flex gap-2 justify-end",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { type: "border" },
                                      on: { click: _vm.cancel },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("common.back")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("permission-popup", {
                        attrs: {
                          visible: _vm.showPopupPermission,
                          filters: _vm.filtersGridPermission,
                        },
                        on: {
                          selected: _vm.addPermissionsList,
                          close: _vm.closePopup,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }